import React, { AnchorHTMLAttributes } from "react";
import { useLocale } from "next-intl";
import classNames from "classnames";

import type { ButtonColor } from "./button.types";
import css from "./button.module.scss";

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  color?: ButtonColor;
  href: string;
  outlined?: boolean;
}

export function ButtonExternalLink({
  children,
  className,
  color = "blue",
  href,
  outlined,
  target = "_blank",
  ...props
}: Props) {
  const locale = useLocale();

  const classes = classNames(css.button, className, css[color], { [css.outlined]: outlined });

  return (
    <a className={classes} href={href.replace("[locale]", locale)} target={target} {...props}>
      {children}
    </a>
  );
}
