import React, { SVGAttributes } from "react";

interface Props extends SVGAttributes<SVGElement> {
  brand: "audioteka" | "audioteka-klub";
}

export function Logo({ brand, ...props }: Props) {
  return (
    <svg {...props}>
      <use href={`/img/logo.svg#${brand}`} />
    </svg>
  );
}
