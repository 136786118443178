/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";

import { Container } from "app-components/container";

import { offerBoxesConfig } from "./offer-boxes.config";
import { OfferBox } from "./offer-box/offer-box";
import { OfferFooter } from "./offer-footer";
import { OfferTop } from "./offer-top";
import { TabBtnGroup } from "./tab-btn-group";
import css from "./offer-boxes.module.scss";

export function OfferBoxes() {
  const [activeTabIdx, setActiveTabIdx] = useState<number>(0);

  return (
    <div className={css.offerBoxes}>
      <Container className={css.container}>
        <OfferTop />
        <TabBtnGroup activeTabIdx={activeTabIdx} onClick={(tabIdx) => setActiveTabIdx(tabIdx)} />
        <div className={css.tabPanel} role="tabpanel">
          {offerBoxesConfig[activeTabIdx].items.map((offer, offerIndex) => (
            <OfferBox key={`offer-${activeTabIdx}-item-${offerIndex}`} offer={offer} />
          ))}
        </div>
        <OfferFooter />
      </Container>
    </div>
  );
}
