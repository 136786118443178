import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import css from "./container.module.scss";

interface Props extends HTMLAttributes<HTMLDivElement> {
  size?: "default" | "compact" | "full";
}

export function Container({ children, className, size = "default", ...props }: Props) {
  return (
    <div className={classNames(css.container, className, size !== "default" && css[size])} {...props}>
      {children}
    </div>
  );
}
