import React from "react";

import { Logo } from "app-components/logo";
import { Text } from "app-components/text";

import css from "./offer-top.module.scss";

export function OfferTop() {
  return (
    <div className={css.offerTop}>
      <Logo className={css.clubLogo} brand="audioteka-klub" />
      <Text className={css.slogan} type="heading4">
        Zostań naszym klubowiczem
      </Text>
    </div>
  );
}
